.Main {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .AppLinkWrapper {
    display: flex;
    align-self: flex-end;
  }

  .Redirect {
    width: 100%;
    white-space: nowrap;
  }

  .AppLink,
  .AppLink:hover,
  .AppLink:focus,
  .AppLink:visited {
    text-decoration: none;
    margin: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #000000;

    img {
      height: 150px;
      width: auto;
    }

    .Text {
      margin-bottom: 16px;
    }

    .Heading {
      font-size: 1.25rem;
      margin: 0;
      font-weight: bold;
      color: #0066cc;
    }

    .Icon {
      width: 150px;
      height: 150px;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;

      &.Cover {
        background-size: cover;
      }
    }
  }
}
